import axiosIns from '@/libs/axios'

const controller = 'person'

export default {
  async getAll() {
    return axiosIns.get(controller).then(res => res.data.content)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async register(req) {
    return axiosIns.post(`${controller}/insert`, req).then(res => res.data)
  },
}
